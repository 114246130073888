var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(25)
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Baixar relatório ")])]
              ),
              _c("emc-excel-import-icon", {
                attrs: { param: "ambiences" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar Novo Ambientes ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("EmcLoaderProgress", { attrs: { show: _vm.loadingProgress } }),
      _c("sys-ambience-filter", {
        attrs: { show: _vm.dialogFilter },
        on: {
          "update:show": function($event) {
            _vm.dialogFilter = $event
          },
          filter: function($event) {
            return _vm.filter($event)
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: true,
          "show-append": "",
          "show-filter": "",
          "payload-filter": _vm.payloadFilter
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          },
          "click-filter": function($event) {
            _vm.dialogFilter = true
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.campus",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("EmcBaseAvatar", {
                  staticClass: "mr-2",
                  attrs: {
                    text: item.name_complete.substring(0, 2),
                    src: item.url_avatar,
                    size: 36
                  },
                  on: {
                    click: function($event) {
                      return _vm.showAvatar(item)
                    }
                  }
                }),
                _vm._v(" " + _vm._s(item.campus.abbreviation) + " ")
              ]
            }
          },
          {
            key: "item.type",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.type.name) + " "),
                item.description
                  ? _c("small", [_c("br"), _vm._v(_vm._s(item.description))])
                  : _vm._e()
              ]
            }
          },
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: { inset: "", label: "Exibir apenas ambientes ativos" },
                  model: {
                    value: _vm.onlyActive,
                    callback: function($$v) {
                      _vm.onlyActive = $$v
                    },
                    expression: "onlyActive"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("SysRegisterAmbience", {
        attrs: { model: _vm.model, show: _vm.showForm },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("EmcAlertSnackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      }),
      _c("SysAmbienceShowImages", {
        attrs: { model: _vm.selectedAmbience, show: _vm.showImage },
        on: {
          "update:show": function($event) {
            _vm.showImage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }